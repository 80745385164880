import { isElementLowerThanThreshold } from '../commons/elementThreshold.tools';
const THRESHOLD = 72;
const CONTENT_WRAPPER_CLASS = "js-content-wrapper";
const BTN_SHOW_MORE_SHOW_LESS_CLASS = "js-btn-show";
class Hubheader extends lm.Composant {
  constructor(id) {
    super(id);
    const component = document.getElementById(id);
    const refererElement = component.querySelector(`.${CONTENT_WRAPPER_CLASS}`);
    const button = component.querySelector(`.${BTN_SHOW_MORE_SHOW_LESS_CLASS}`);
    if (refererElement && isElementLowerThanThreshold(refererElement, THRESHOLD)) {
      button.classList.add('kl-hidden');
      refererElement.classList.add('l-hubproject-header__description--no-showmore');
    } else {
      button.addEventListener('click', () => {
        refererElement.setAttribute('data-expanded', `${(refererElement.getAttribute('data-expanded') !== 'true').toString()}`);
        button.setAttribute('aria-expanded', `${(button.getAttribute('aria-expanded') !== 'true').toString()}`);
      });
    }
  }
}
lm.DOMReady(function () {
  const sectionElem = document.querySelectorAll('.component-categorymetameshingenrichment-header');
  for (let i = 0; i < sectionElem.length; i++) {
    const idElem = sectionElem[i].getAttribute('id');
    if (idElem) {
      const hubHeaderObject = {};
      hubHeaderObject.instance = new Hubheader(idElem);
      delete hubHeaderObject.instance;
    }
  }
});